<div class="login-bg-container">
    <div class="left"></div>
    <div [ngClass]="line"> </div>
    <div class="right"></div>
</div>

<div class="login-component">
    <div class="left">
        <div class="logo-container">
            <div class="logo">
                <!-- <img src="..\assets\image\logo-small.svg" alt="logo"> -->
                <img src="..\assets\image\savinda-logo.png" alt="logo">
            </div>
            <div class="title-container">
                <div class="title">
                    <p>Welcome to Savinda ERP</p>
                </div>
                <div class="message">
                    <p>Please login with your user name and password.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="right">

        <div class="login-container">
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onUserLogin()">
                <div class="login-input-group">
                    <div class="login-input-icon">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                            d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
                        </svg>
                    </div>
                    <input class="login-input" type="text" placeholder="User Name" name="username"
                        formControlName="username">
                </div>
                <!-- <div *ngIf="isFormsSubmitedWithErrors" class="text-red-500">
                    <div *ngIf="loginForm.controls['username']?.errors?.['required']">Required.</div>
                </div> -->

                <div class="login-input-group">
                    <div class="login-input-icon">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                            d="M48 256C48 141.1 141.1 48 256 48c63.1 0 119.6 28.1 157.8 72.5c8.6 10.1 23.8 11.2 33.8 2.6s11.2-23.8 2.6-33.8C403.3 34.6 333.7 0 256 0C114.6 0 0 114.6 0 256v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256zm458.5-52.9c-2.7-13-15.5-21.3-28.4-18.5s-21.3 15.5-18.5 28.4c2.9 13.9 4.5 28.3 4.5 43.1v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256c0-18.1-1.9-35.8-5.5-52.9zM256 80c-19 0-37.4 3-54.5 8.6c-15.2 5-18.7 23.7-8.3 35.9c7.1 8.3 18.8 10.8 29.4 7.9c10.6-2.9 21.8-4.4 33.4-4.4c70.7 0 128 57.3 128 128v24.9c0 25.2-1.5 50.3-4.4 75.3c-1.7 14.6 9.4 27.8 24.2 27.8c11.8 0 21.9-8.6 23.3-20.3c3.3-27.4 5-55 5-82.7V256c0-97.2-78.8-176-176-176zM150.7 148.7c-9.1-10.6-25.3-11.4-33.9-.4C93.7 178 80 215.4 80 256v24.9c0 24.2-2.6 48.4-7.8 71.9C68.8 368.4 80.1 384 96.1 384c10.5 0 19.9-7 22.2-17.3c6.4-28.1 9.7-56.8 9.7-85.8V256c0-27.2 8.5-52.4 22.9-73.1c7.2-10.4 8-24.6-.2-34.2zM256 160c-53 0-96 43-96 96v24.9c0 35.9-4.6 71.5-13.8 106.1c-3.8 14.3 6.7 29 21.5 29c9.5 0 17.9-6.2 20.4-15.4c10.5-39 15.9-79.2 15.9-119.7V256c0-28.7 23.3-52 52-52s52 23.3 52 52v24.9c0 36.3-3.5 72.4-10.4 107.9c-2.7 13.9 7.7 27.2 21.8 27.2c10.2 0 19-7 21-17c7.7-38.8 11.6-78.3 11.6-118.1V256c0-53-43-96-96-96zm24 96c0-13.3-10.7-24-24-24s-24 10.7-24 24v24.9c0 59.9-11 119.3-32.5 175.2l-5.9 15.3c-4.8 12.4 1.4 26.3 13.8 31s26.3-1.4 31-13.8l5.9-15.3C267.9 411.9 280 346.7 280 280.9V256z" />
                        </svg>
                    </div>
                    <input class="login-input" type="password" placeholder="Password" name="password"
                        formControlName="password" maxlength="20">
                </div>

                <!-- <div *ngIf="isFormsSubmitedWithErrors" class="text-red-500">
                    <div *ngIf="loginForm.controls['password']?.errors?.['required']">Required.</div>
                </div> -->

                <button class="login-btn">
                    <div *ngIf="isSubmited" class="loading-spinner"></div>
                    LOGIN
                </button>
            </form>

            <div class="registor-msg">
                <p>
                    <span class="">Don't you have an account?</span>
                    <a href="#">Register now</a>
                </p>
            </div>


            <div class="flex items-center">
                <div>
                    <hr class="w-36  bg-black h-0.5 opacity-40">
                </div>
                <div class="text-lg ml-5 mr-5">OR</div>
                <div>
                    <hr class="w-32  bg-black h-0.5 opacity-40">
                </div>
            </div>


            <span class="opacity-80">Sign in with</span>
            <div class="option">
                <div><a href=""><img src="..\assets\image\google.png" alt="google icon" width="25"></a></div>
                <div><a href=""><img src="..\assets\image\apple.png" alt="apple icon" width="22"></a></div>
                <div><a href=""><img src="..\assets\image\microsoft.png" alt="microsoft icon" width="25"></a></div>
            </div>
        </div>
    </div>

</div>